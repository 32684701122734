<template>
  <div class="ad-consume padding-20 box-shadow-light-grey">
    <!-- 条件搜索 -->
    <div class="filter-box">
      <!-- <h4 class="filter-title">今日广告</h4> -->
      <div class="row">
        <!-- 单独公众号 -->
        <el-select
          v-model="params.accountChannelId"
          filterable
          style="width: 180px"
          reserve-keyword
          placeholder="选择公众号"
          clearable
        >
          <el-option
            v-for="(item, index) in officialList"
            :label="item.channelName"
            :value="item.id"
            :key="index"
          >
            <span style="float: left">{{ item.channelName }}</span>
            <span style="float: right; margin-left: 15px; font-size: 13px"
              >{{ item.platformStr }} - {{ item.uname }}</span
            >
          </el-option>
        </el-select>
        <!-- 内容 -->
        <el-input
          style="width: 200px"
          v-model="params.content"
          placeholder="回复内容"
          clearable
          @keyup.enter.native="handleSearch"
        ></el-input>
        <el-date-picker
          v-model="params.subDate"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择关注日期"
        >
        </el-date-picker>
        <!-- 分组 -->
        <el-select
          v-model="params.groupType"
          filterable
          style="width: 180px"
          reserve-keyword
          placeholder="分组"
        >
          <el-option label="内容分组" :value="1"> </el-option>
          <el-option label="用户分组" :value="2"> </el-option>
        </el-select>
        <el-button
          type="primary"
          @click="handleSearch"
          style="margin-left: 5px"
          :loading="loading"
        >
          搜索
        </el-button>
      </div>
    </div>
    <div class="data-box">
      <!-- 表格数据 -->
      <el-table
        v-loading="loading"
        :data="currentData"
        style="width: 100%; height: 650px; overflow-y: auto"
      >
        <el-table-column prop="channelName" label="公众号" width="250">
        </el-table-column>
        <el-table-column prop="contentStr" label="内容"> </el-table-column>
        <el-table-column prop="num" label="发送次数"> </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: right"
        background
        :page-size="pageSize"
        @current-change="handlePageChange"
        :current-page="page"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { pickerOptions } from "@/assets/js/options";
import { getWechatKeyCount } from "@/api/statics";
export default {
  data() {
    return {
      tableData: [],
      currentData: [],
      params: {},
      pickerOptions,
      pageSize: 15,
      total: 0,
      page: 1,
      loading: false,
    };
  },
  methods: {
    handlePageChange(page) {
      this.page = page;
      let startNum = (this.page - 1) * this.pageSize;
      this.currentData = this.tableData.slice(
        startNum,
        startNum + this.pageSize
      );
    },
    handleSearch() {
      this.page = 1;
      this.loading = true;
      getWechatKeyCount({
        page: this.page,
        content: this.params.content,
        accountChannelId: this.params.accountChannelId,
        groupType: this.params.groupType,
        subDate: this.params.subDate,
      })
        .then((res) => {
          this.tableData = res;
          let startNum = (this.page - 1) * this.pageSize;
          this.currentData = this.tableData.slice(
            startNum,
            startNum + this.pageSize
          );
          this.total = res.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters(["officialList"]),
  },
  created() {
    this.$set(this.params, "groupType", 1);
    this.handleSearch();
  },
};
</script>

<style lang='scss' scoped>
.ad-consume {
  background-color: #fff;
  border-radius: 6px;
}
// 筛选条件框
.filter-box {
  border-radius: 6px;
  // border: 1px solid #eceff2;
  .filter-title {
    color: $main-text;
    margin-bottom: 20px;
    .iconfont {
      font-size: 12px !important;
    }
  }
}
</style>